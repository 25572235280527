import React, { createContext, useContext, useEffect, useState } from "react";
import ApiService from "services/ApiService";
import { useNotificationHandlerContext } from "./Resource/NotificationHandlerContext";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { notify } = useNotificationHandlerContext();
  const [user, setUser] = useState(null);
  const [navigation, setNavigation] = useState(null);
  const [loading, onLoading] = useState(false);
  const [isLoginFacebook, onIsLoginFacebook] = useState(false);
  const [urlRedirect, onUrlRedirect] = useState('');

  const Login = async ({email, password}) => {
    onLoading(true);
    await ApiService
      .post("login", {
        email: email,
        password: password,
      })
      .then((resp) => {
        if (resp?.access_token !== undefined) {
          localStorage.setItem("@App:token", resp.access_token);
          localStorage.setItem("@App:user", JSON.stringify(resp.user));
          localStorage.setItem("@App:navigation", JSON.stringify(resp.navigation));

          setNavigation(resp.navigation);
          setUser(resp.user);
        } else {
          notify('error', 'authentication', resp.message);
          onLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 401) {
          notify('error', 'authentication', "Sistema indisponível no momento.");
          onLoading(false);
        } else {
          notify('error', 'authentication', "Sistema indisponível no momento.");
          onLoading(false);
        }
      });
  };

  const Logout = () => {
    onLoading(false);
    setUser(null);
    localStorage.removeItem("@App:user");
    localStorage.removeItem("@App:access");
    localStorage.removeItem("@App:token");
    localStorage.removeItem("@App:navigation");
    localStorage.removeItem("@App:language");
  };

  /** Recuperando o usuário e token da sessão */
  useEffect(() => {
    const storagedUser = localStorage.getItem("@App:user");
    const storagedToken = localStorage.getItem("@App:token");
    const storagedNavigation = localStorage.getItem("@App:navigation");
   
    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      setNavigation(JSON.parse(storagedNavigation));
    }
  }, []);

  const getToken = () => {
    return localStorage.getItem("@App:token");
  }

  const ConfirmCode = async ({email, code}) => {
    return ApiService
      .post("confirm-code", {
        email: email,
        code: code
      })
  };

  const ChangePassword = async ({email, password}) => {
    return ApiService
      .post("change-password", {
        email: email,
        password: password
      })
  };

  const Forgot = async ({email}) => {
    return ApiService
      .post("forgot-password", {
        email: email
      });
  };

  const isOnlyView = () => {
    let view = false;
    navigation.map((nav) => {
      if (nav.submenu.length > 0) {
        return nav.submenu.map((sub) => {
          if (`/${sub.path}` === window.location.pathname) {
            if (sub.only_view === 1) {
              view = true;
            }
          }
        });
      } else {
        if (`/${nav.path}` === window.location.pathname) {
          if (nav.only_view === 1) {
            view =  true;
          }
        }
      }
    });
    
    return view;
  }

  const onVerifyToken = () => {
    onUrlRedirect('');
    ApiService
      .get("auth/token")
      .then((resp) => {
        if (resp.valid === false) {
          onIsLoginFacebook(true);
          onUrlRedirect(resp.login_redirect);
        } else {
          onIsLoginFacebook(false);
          onUrlRedirect('');
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 401) {
          Logout();
        }
      });
  }

  useEffect(() => {
    onVerifyToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{ 
        signed: Boolean(user), 
        user, 
        setUser,
        Login, 
        Logout, 
        getToken, 
        loading,
        Forgot,
        ConfirmCode,
        ChangePassword,
        navigation,
        isOnlyView,
        isLoginFacebook,
        onIsLoginFacebook,
        urlRedirect,
        onUrlRedirect,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export default AuthContext;
