import { isEmpty, post, get, put, patch, del, uploadFile } from "utils/Helper";

class ApiService {
  urlApi;

  constructor() {
    const { REACT_APP_HOSTNAME_API } = process.env;

    if (isEmpty(REACT_APP_HOSTNAME_API)) {
      throw new Error("Nenhuma URL para a API definida");
    }

    this.urlApi = REACT_APP_HOSTNAME_API;
  }

  post(path, payload) {
    return post(`${this.urlApi}${path}`, payload);
  }

  get(path, params, sorter = null) {
    return get(`${this.urlApi}${path}`, params, sorter);
  }

  put(path, params) {
    return put(`${this.urlApi}${path}`, params);
  }

  patch(path, params) {
    return patch(`${this.urlApi}${path}`, params);
  }

  delete(path) {
    return del(`${this.urlApi}${path}`);
  }

  upload(path, payload) {
    return uploadFile(`${this.urlApi}${path}`, payload);
  }
}

export default new ApiService();
