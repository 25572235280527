import { Button, Modal } from "antd";
import { useAuth } from "contexts/AuthContext";
import { FacebookOutlined } from "@ant-design/icons";

export const FacebookLoginModal = () => {
  const { isLoginFacebook, urlRedirect } = useAuth();

  console.log(isLoginFacebook);

  return (
    <Modal open={isLoginFacebook} closable={false} footer={null} className="text-center">
      <h2>Seu login com o Facebook expirou</h2>
      <Button href={urlRedirect} type="primary" icon={<FacebookOutlined />} style={{ backgroundColor: "#3b5998" }}>
        {" "}
        Entrar com o Facebook
      </Button>
    </Modal>
  );
};
