import React from "react";

import { SecurityScanOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import PasswordChecklist from "react-password-checklist";

const PasswordChecker = ({ handleChangePassword, loading }) => {

    const [passwordAgain, setPasswordAgain] = useState("");
    const [password, setPassword] = useState("");
	const [passwordIsValid, setPasswordIsValid] = useState(false);

    const handleValid = (isValid) => {
        console.log(isValid);
        setPasswordIsValid(isValid);
    }

    return (
        <>
        <Form 
            layout="vertical" 
            name="recovery-form"
            size="large"
        >
            <Form.Item 
                name="password" 
                label="Informe sua nova senha"
                rules={[
                    { 
                        required: true,
                        message: "Por favor, informe uma senha válida",
                    },
                ]}>
                <Input.Password prefix={<SecurityScanOutlined className="text-primary" />} onChange={ e => setPassword(e.target.value) } />
            </Form.Item>
            <Form.Item 
                name="confirmPassword" 
                label="Confirme sua nova senha"
                className="mb-10"
                rules={[
                    { 
                        required: true,
                        message: "As senhas não correspondem",
                    },
                ]}>
                <Input.Password prefix={<SecurityScanOutlined className="text-primary" />} onChange={ e => setPasswordAgain(e.target.value) }/>
            </Form.Item>
            <PasswordChecklist
				rules={["minLength","specialChar","number","capital","match"]}
				minLength={8}
				value={password}
				valueAgain={passwordAgain}
				onChange={(isValid) => { handleValid(isValid) }}
                messages={{
					minLength: "A senha tem pelo menos 8 caracteres.",
					specialChar: "A senha possui caracteres especiais.",
					number: "A senha tem um número.",
					capital: "A senha tem letra maiúscula.",
					match: "A confirmação de senha combinam.",
				}}
			/>
        </Form>
        <Button loading={loading} type="primary" onClick={() => { handleChangePassword(password, passwordIsValid)}} className="mt-2" block>{loading? 'Enviando...' : 'Confirmar'}</Button>
        </>
    );
}

export default PasswordChecker;