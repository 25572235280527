import React, { createContext, useContext, useRef, useState } from "react";
import { useNotificationHandlerContext } from "./Resource/NotificationHandlerContext";
import ApiService from "services/ApiService";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    // eslint-disable-next-line
    // @ts-ignore
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadContext = createContext();

export function UploadProvider({ children }) {
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [loading, onLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const uploadButtonRef = useRef();
  const { notify } = useNotificationHandlerContext();

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => console.log(newFileList);

  const onUpload = ({ file, onSuccess, onError, onProgress }) => {
    onLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    ApiService.upload(`uploads`, formData)
      .then((response) => {
        onLoading(false);
        onProgress({ percent: 100 }, file);
        onSuccess(file);

        const newFile = response;

        setFileList((prevList) => {
          return [...prevList, newFile];
        });
      })
      .catch(() => {
        notify(
          "error",
          "notification",
          "Erro no envio dos dados.",
          [],
          "notification"
        );
        onLoading(false);
        onError("Erro no envio dos dados.");
      });
  };

  const onDeleteFile = (file) => {
    setFileList((prevList) => {
      const index = prevList.indexOf(file);
      const newFileList = prevList.slice();
      newFileList.splice(index, 1);
      return newFileList;
    });
  };

  return (
    <UploadContext.Provider
      value={{
        handlePreview,
        previewImage,
        previewOpen,
        fileList,
        handleChange,
        setPreviewOpen,
        setPreviewImage,
        onUpload,
        setFileList,
        uploadButtonRef,
        loading,
        onLoading,
        onDeleteFile,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
}

export function useUploadContext() {
  const context = useContext(UploadContext);
  return context;
}

export default UploadContext;
